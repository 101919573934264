import React from "react"

import mcrCodes from "../images/mcrcodes.png"
import cl from "../images/CL.png"
import gamma from "../images/gamma.png"
import zeta from "../images/zeta.jpeg"

import { animateScroll } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons"

const Projects = () => {
  const handleScrollToTop = () => {
    animateScroll.scrollToTop()
  }
  return (
    <div className="Projects" id="projects">
      <h1 className="main-projects-header">Experience</h1>

      <div className="project-container">
        <h3 className="indi-project-header">
          Mission Labs - Zeta telephony app
        </h3>

        <img className="screenshot" src={zeta} alt="screenshot" />

        <div className="project-side">
          <p className="project-para">
            I've recently completed a React and TypeScript telephony application
            with chat and video calling features, all organised within an NX
            monorepo. The project emphasises a systematic and scalable approach,
            leveraging React for a dynamic user interface and TypeScript for
            code reliability. The NX monorepo structure streamlines development,
            allowing efficient code sharing across components. The addition of
            chat and video calling functionalities enhances the application's
            versatility, offering a comprehensive solution for real-time
            communication. The project underscores a commitment to a
            well-organised and maintainable codebase while delivering an
            integrated telephony solution.
          </p>
        </div>
      </div>

      <div className="project-container">
        <h3 className="indi-project-header">
          Mission Labs - User Management GUI
        </h3>

        <img className="screenshot" src={gamma} alt="screenshot" />

        <div className="project-side">
          <p className="project-para">
            In 2023 I have finished a large project for our parent company Gamma
            - a comprehensive user management platform. This platform empowers
            admins to efficiently organise users into various groups and manage
            their call settings with ease. As a key member of the development
            team, I have been involved in this project from the very beginning,
            including creating a robust component library to build the entire
            graphical user interface (GUI). I closely collaborated with my team
            members and designers to ensure that we achieved an impressive 75%
            test coverage and made sure that the platform is fully accessible,
            adhering to W3C standards. The platform is built using cutting-edge
            technologies such as React, TypeScript, Chakra UI, Redux Toolkit,
            and a variety of other packages, making it a solid, reliable, and
            user-friendly product.
          </p>
        </div>
      </div>

      <div className="project-container laptop">
        <h3 className="indi-project-header">Mission Labs - CircleLoop</h3>
        <div className="switch">
          <img className="screenshot" src={cl} alt="screenshot" />
          <div className="project-side">
            <p className="project-para">
              Circleloop is a cutting-edge business phone system developed by
              Mission Labs. As a Frontend Software Engineer, I work on various
              platforms such as customer-facing apps, including the webapp and
              desktop app, as well as our internal 'Super' app, which allows
              access to all Circleloop features. My responsibilities include
              implementing new features, improving existing code, and
              maintaining all platforms. I use modern technologies such as
              React, Redux, and Saas, as well as various libraries and packages
              to develop these platforms.
            </p>
          </div>
        </div>
      </div>

      <div className="project-container end">
        <h3 className="indi-project-header">Manchester Codes</h3>

        <img className="screenshot" src={mcrCodes} alt="screenshot" />

        <div className="project-side">
          <p className="project-para">
            I learned to code with Manchester Codes, recently rebranded as
            Command Shift. It is an intensive 24-week bootcamp that provides a
            comprehensive curriculum for full-stack web development. The program
            is designed to be immersive, with a focus on hands-on learning and
            practical application. I worked in an agile development environment,
            learning the key skills in full stack development such as HTML, CSS,
            JavaScript, and various web development frameworks such as React and
            Node.js. The program also covered important topics such as version
            control with Git, database design, and Agile methodologies. Through
            working on various projects and group assignments, I was able to
            apply my knowledge and develop my problem-solving skills. The
            bootcamp also provided support in terms of mentorship and career
            services to help me transition into a career as a frontend
            developer.
          </p>
        </div>
      </div>

      <span className="scroll-button" onClick={() => handleScrollToTop()}>
        <FontAwesomeIcon icon={faChevronCircleUp} size="2x" />
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
        <path
          fill="#001e1e"
          fillOpacity="1"
          d="M0,64L40,58.7C80,53,160,43,240,80C320,117,400,203,480,218.7C560,235,640,181,720,181.3C800,181,880,235,960,224C1040,213,1120,139,1200,122.7C1280,107,1360,149,1400,170.7L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  )
}

export default Projects
