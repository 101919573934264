import React from "react"
import me from "../images/me.png"
import { SocialIcon } from "react-social-icons"

import js from "../images/js.svg"
import react from "../images/react.svg"
import typescript from "../images/typescript.png"
import css from "../images/css.svg"
import sass from "../images/sass.svg"
import node from "../images/node.svg"

const About = () => {
  return (
    <div className="About" id="about">
      <h1 className="about-header">About</h1>
      <div className="all-info">
        <div className="left">
          <img className="me-pic" src={me} alt="me" />
          <div className="details-list">
            <span className="list-item">
              <SocialIcon
                target="#"
                url="https://github.com/ethanrdj"
                network="github"
                bgColor="#000000"
                style={{ height: 25, width: 25 }}
              />
              &nbsp;&nbsp;
              <a
                className="social-links"
                href="https://www.github.com/ethanrdj"
                target="#"
              >
                github.com/ethanrdj
              </a>
            </span>
            <span className="list-item">
              <SocialIcon
                url="https://www.linkedin.com/in/ethanrdj/"
                target="#"
                network="linkedin"
                bgColor="#000000"
                style={{ height: 25, width: 25 }}
              />
              &nbsp;&nbsp;
              <a
                className="social-links"
                href="https://www.linkedin.com/in/ethanrdj/"
                target="#"
              >
                linkedin.com/in/ethanrdj
              </a>
            </span>
            <span className="list-item">
              <SocialIcon
                target="#"
                url="mailto: ethanrdavis96@gmail.com"
                network="mailto"
                bgColor="#000000"
                style={{ height: 25, width: 25 }}
              />
              &nbsp;&nbsp;
              <a
                className="social-links"
                href="mailto: ethanrdavis96@gmail.com"
                target="#"
              >
                ethanrdavis96@gmail.com
              </a>
            </span>
          </div>
        </div>
        <div className="right">
          <p className="about-para">
            I am a front-end developer with 2.5 years of experience working with
            React, React Native, Expo, TypeScript, among others. I have
            experience working in an agile environment in teams, utilising pair
            programming to collaborate effectively with others. I am known for
            being hardworking and having a passion for technology. I always
            strive to stay up-to-date with the latest industry developments in
            order to improve my skills and deliver the best possible results for
            my projects. I am a team player, fast learner and always ready to
            take on new challenges.
          </p>
          <div className="icons">
            <img src={js} alt="js-icon" className="icon-small" />
            <img src={react} alt="react-icon" className="icon" />
            <img src={typescript} alt="html-icon" className="icon-ts" />
            <img src={css} alt="css-icon" className="icon" />
            <img src={sass} alt="sass-icon" className="icon-small" />
            <img src={node} alt="node-icon" className="icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
