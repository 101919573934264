import React, { useEffect, useState } from "react"
import Navbar from "./Navbar"
import Home from "./Home"
import About from "./About"
import Projects from "./Projects"
import Contact from "./Contact"
import Footer from "./Footer"
import Loader from "./Loader"

import "../styles/main.scss"

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setInterval(() => {
      setLoading(false)
    }, 3000)
  })
  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <Home />
          <About />
          <Projects />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  )
}

export default App
