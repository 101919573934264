import React from "react";

const Footer = () => {
  return (
    <div className="Footer">
      <p className="me">©Code and Design by Ethan Davis</p>
    </div>
  );
};

export default Footer;
